import React from 'react'
import { graphql } from 'gatsby'

import SEO from 'src/components/seo'
import Header from 'src/components/header'
import Hero from 'src/components/hero'
import About from 'src/components/about'
import Schedule from 'src/components/schedule'
import Speakers from 'src/components/speakers'
import Location from 'src/components/location'
import Sponsors from 'src/components/sponsors'
import Footer from 'src/components/footer'

const getSpeakerData = speakers => {
  return speakers.edges.map(({ node }) => {
    return {
      id: node.id,
      name: node.frontmatter.name,
      title: node.frontmatter.title,
      description: node.excerpt,
      image: node.frontmatter.image.childImageSharp.fluid,
      path: node.frontmatter.path,
    }
  })
}

const getScheduleData = schedule => {
  let scheduleData = schedule.edges.map(({ node }) => {
    return {
      id: node.id,
      time: node.frontmatter.time,
      name: node.frontmatter.name,
      title: node.frontmatter.title,
      path: node.frontmatter.path,
    }
  })

  // TODO: This is pure disgusting horseshit from here. But I was very-very-very pressed for time :(

  scheduleData.push({
    id: 'opening',
    time: '13:00 - 14:00',
    name: '',
    title: 'Kapunyitás, regisztráció',
  })

  scheduleData.push({
    id: 'speech',
    time: '14:00 - 14:10',
    name: '',
    title: 'Megnyitó beszéd',
  })

  scheduleData.push({
    id: 'break_01',
    time: '15:05 - 15:30',
    name: '',
    title: '1. szünet',
  })

  scheduleData.push({
    id: 'break_02',
    time: '16:35 - 17:00',
    name: '',
    title: '2. szünet',
  })

  scheduleData.push({
    id: 'break_03',
    time: '18:00 - 18:25',
    name: '',
    title: '3. szünet',
  })

  scheduleData.sort((a, b) => {
    if (a.time < b.time) return -1
    if (a.time > b.time) return 1
    return 0
  })

  // </horseshit>

  return scheduleData
}

const IndexPage = ({ data }) => (
  <>
    <SEO
      title="Főoldal"
      keywords={[`konferencia`, `rendezvény`, `Láthatatlan Egyetem`]}
    />
    <Header siteTitle="III. Láthatatlan Konferencia" showNav={true} />
    <Hero
      title="III. Láthatatlan Konferencia"
      subtitle="2019. 02. 23."
      subsubtitle="Budapest"
    />
    <main className="container">
      <section id="esemenyrol" className="row anchor content-block">
        <div className="col">
          <h2>Az eseményről</h2>
          <About />
        </div>
      </section>
      <p className="text-center my-5">
        <a
          className="btn btn-primary"
          role="button"
          href="http://salesautopilot.s3.amazonaws.com/newsletter/letter/nl90422/ns169359/subscribe.html"
        >
          <span className="h1">Megveszem a jegyemet!</span>
        </a>
      </p>
      <section id="program" className="row anchor content-block">
        <div className="col">
          <h2>Program</h2>
          <Schedule speakerData={getScheduleData(data.schedule)} />
        </div>
      </section>
      <section id="eloadok" className="row anchor content-block">
        <div className="col">
          <h2>Előadók</h2>
          <Speakers speakerData={getSpeakerData(data.speakers)} />
        </div>
      </section>
      <section id="helyszin" className="row anchor content-block">
        <div className="col">
          <h2>Helyszín</h2>
          <Location />
        </div>
      </section>
      <section id="vasarlas" className="row anchor content-block">
        <div className="col">
          <h2>Jegyvásárlás</h2>
          <p className="my-5 text-center">
            <a
              className="btn btn-primary"
              role="button"
              href="http://salesautopilot.s3.amazonaws.com/newsletter/letter/nl90422/ns169359/subscribe.html"
            >
              <span className="h1">Megveszem a jegyemet!</span>
            </a>
          </p>
        </div>
      </section>
      <section id="tamogatok" className="row anchor content-block">
        <div className="col">
          <h2>Támogatók</h2>
          <Sponsors />
        </div>
      </section>
    </main>
    <Footer />
  </>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    speakers: allMarkdownRemark(
      filter: { frontmatter: { path: { regex: "//eloadok/.*/" } } }
      sort: { fields: [frontmatter___name], order: ASC }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            path
            name
            title
            image {
              childImageSharp {
                fluid(maxWidth: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
    schedule: allMarkdownRemark(
      filter: { frontmatter: { path: { regex: "//eloadok/.*/" } } }
      sort: { fields: [frontmatter___time], order: ASC }
    ) {
      edges {
        node {
          id
          frontmatter {
            path
            time
            name
            title
          }
        }
      }
    }
  }
`

export default IndexPage
